
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.818);
    border-bottom: 1px solid rgba(19, 8, 3, 0.05);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    padding: 1.2rem 80px;
    width: 100%;
    z-index: 111111111;
}
.logo {
    display: flex;
}
.logo img {
    width: 140px;
}

.nav-links {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 380px;
    position: absolute;
    left: 50%;
    /* top: 50%; */
    transform: translate(-50%);
}
.nav-links a {
    color:  #131212;
}

.navbar-cta {
    display: flex;
    column-gap: 1.5rem;
}
.navbar-cta button {
    height: 50px;
    width: 140px;
    border-radius: 5px;
    background: #060D1C;
    border: 2px solid #060D1C;
    border-radius: 55px;
    font-size: 15px;
    color: #FFFFFF;
}

.navbar-cta button:nth-child(1) {
    width: fit-content;
    background: none;
    color: #131212;
    border: none;
}
.hambuger {
    display: none;
}
.hambuger div {
    transition: all 500ms;
}
.hambuger.open-state div:nth-child(2) {
    opacity: 0;
}
.hambuger.open-state div:nth-child(1) {
    rotate: -45deg;
    transform: translate(-50%, 50%);
}
.hambuger.open-state div:nth-child(3) {
    rotate: 45deg;
    transform: translate(-50%, -50%);
}

@media (max-width: 1086px) {
    .navbar {
        padding:1.4rem 24px;
    }
    .nav-links {
        max-width: 280px;
    }
    .nav-links a {
        font-size: 15px;    
    }
}
@media (max-width: 786px) {
    .navbar {
        padding:1.4rem 1rem;
    }
    .nav-links {
        display: none;
    }
    .nav-links.open-state {
        display: flex;
        flex-direction: column;
    }
    .navbar-cta {
        display: none;
    }
    .navbar-cta.open-state {
        display: flex;
        flex-direction: row;
        margin-left: 2.5rem;
        cursor: pointer;
    }
    .navbar-cta.open-state button {
        z-index: 10;
    }
    .hambuger {
        display: flex;
        row-gap: .4rem;
        flex-direction: column;
    } 
    .hambuger div {
        width: 32px;
        height: 3px;
        background: #131212;
    }
}
@media (max-width: 486px) {
    .navbar {
        padding:1rem;
    }
    .nav-links {
        display: none;
    }
}