.newsletter-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:  #060D1C;
    height: 300px;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    margin-top: 2rem;
    border-radius: 300px;
}

.newsletter-container header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.newsletter-container header h3 {
    font-weight: 800;
    font-size: 32px;
    color: #FFF;
}
.newsletter-container header p {
    color:#F0F0F0;
    margin-top: .5rem;
    max-width: 450px;
    text-align: center;
    margin-top: .5rem;
}
.newsletter-form {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 1.5rem;
    width: 100%;
}
.newsletter-form input {
    width: min(50%, 638px);
    /*width: 638px;*/
    height: 55px;
    background: #F8F8F8;
    border: none;
    outline: none;
    border-radius: 55px;
    padding-left: 1rem;
    transition: width 500ms;
}
.newsletter-form input:focus {
    width: 52%;
}
.newsletter-form button {
    height: 40px;
    width: 150px;
    outline: none;
    border: none;
    position: absolute;
    top: 7.5px;
    right: 29%;
    background: #060D1C;
    color: #FFF;
    border-radius: 50px;
}
.newsletter-form button .iconify {
    display: none;
}
/* @media (min-width: 1086px) {

} */
@media (max-width: 786px) {
    .newsletter-container header {
        justify-content: left;
        align-items: flex-start;
        padding: 0 1rem;
    }
    .newsletter-container header h3 {
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        margin-top: 1rem;
        text-align: left;
    }
    .newsletter-container header p {
        font-size: 15px;
        text-align: left;
        max-width: 300px;
        margin-top: .3rem;
    }
    .newsletter-form {
        padding: 0 1rem;
    }
    .newsletter-form button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 46px !important;
        right: 1.5rem;
    }
    .newsletter-form button p {
        display: none;
    }
    .newsletter-form button .iconify {
        display: flex;
        font-size: 28px;
        margin: auto;
    }

    /*mobile resp*/
    .newsletter-container {
        border-radius: 5%;
        width: 95%;
    }
    .newsletter-form input {
        width: 75%;
    }
    .newsletter-form input:focus {
        width: 80%;
    }
    .newsletter-form button {
        right: 17%;
    }
}
