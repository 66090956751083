.create-transaction-container header {
  display: flex;
  justify-content: space-between;
  margin-top: 180px;
  padding: 0 5%;
}
.create-transaction-container header div {
  display: flex;
  align-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.create-transaction-container header span {
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.create-transaction-container header span button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  width: 100%;
  min-width: 200px;
  height: 50px;
  border-radius: 50px;
  font-size: 16px;
}
.create-transaction-container header span .save-draft-btn {
  border: 0.638138px solid #CC2828;
}
.create-transaction-container header span .create-link-btn {
  background: #8490FF;
  color: #FFF;
}
.create-transaction-container main {
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 873px;
  margin: 0 auto;
  margin-top: 56px;
}
.create-transaction-container main h4 {
  font-family: "cabinet-grotesk-bold";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #060D1C;
}
.create-transaction-container main form {
  display: flex;
  flex-direction: column;
  margin-top: 49px;
  margin-bottom: 2rem;
  background: rgba(132, 144, 255, 0.1);
  border-radius: 12px;
  padding: 3rem 2rem;
}
.create-transaction-container main form label {
  font-family: "cabinet-grotesk-medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #060D1C;
  margin-bottom: 1.5rem;
}
.create-transaction-container main form input {
  border: 1px solid rgba(132, 144, 255, 0.5);
  border-radius: 5px;
  height: 54px;
  background: none;
  padding: 0 1.5rem;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  margin-bottom: 42px;
}
.create-transaction-container main form input::-moz-placeholder {
  font-size: 16px;
}
.create-transaction-container main form input:-ms-input-placeholder {
  font-size: 16px;
}
.create-transaction-container main form input::placeholder {
  font-size: 16px;
}
.create-transaction-container main form .payment-options {
  display: flex;
  flex-direction: column;
}
.create-transaction-container main form .payment-options span {
  display: flex;
  margin-bottom: 0;
  align-items: center;
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem;
}
.create-transaction-container main form .payment-options span input {
  margin-bottom: 0;
  width: 24px;
}
.create-transaction-container main form .payment-options span p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #060D1C;
}/*# sourceMappingURL=create-transaction-link.css.map */