.dashboard-navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 120px;
    background-color: #060D1C;
    border-bottom: 1px solid rgba(101, 100, 104, 0.19);
    padding: 0 5%;
    z-index: 1111;
}
.logo h3 {
    font-family: 'cabinet-grotesk-bold';
    font-size: 36px;
    color: #FFF;
}
.dashboard-nav-links {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 480px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.dashboard-nav-links .link{
    font-family: 'cabinet-grotesk-regular';
    color: #FFF;
    font-size: 20px;
    text-decoration: none;
}
.dashboard-nav-links li a:hover,
.dashboard-nav-links #active {
    color: #4CD3E3;
    transition: 0.4s ease-in-out;
}
/* .dashboard-nav-links .active-link {
    border-bottom: 2px solid #000;
    padding-bottom: 1rem;
} */


.user-avatar img{
    width: 60px;
}