@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  overflow-X: hidden;
  box-sizing: border-box;
}

@font-face {
  font-family: 'cabinet-grotesk-regular';
  src: url(./Fonts/CabinetGrotesk_Complete/Fonts/OTF/CabinetGrotesk-Regular.otf);
}

@font-face {
  font-family: 'cabinet-grotesk-medium';
  src: url(./Fonts/CabinetGrotesk_Complete/Fonts/OTF/CabinetGrotesk-Medium.otf);
}

@font-face {
  font-family: 'cabinet-grotesk-bold';
  src: url(./Fonts/CabinetGrotesk_Complete/Fonts/OTF/CabinetGrotesk-Bold.otf);
}
@font-face {
  font-family: 'cabinet-grotesk-extrabold';
  src: url(./Fonts/CabinetGrotesk_Complete/Fonts/OTF/CabinetGrotesk-Extrabold.otf);
}
@font-face {
  font-family: 'cabinet-grotesk-black';
  src: url(./Fonts/CabinetGrotesk_Complete/Fonts/OTF/CabinetGrotesk-Black.otf);
}
@font-face {
  font-family: 'cabinet-grotesk-variable';
  src: url(./Fonts/CabinetGrotesk_Complete/Fonts/Variable/CabinetGrotesk-Variable.ttf);
}

body {
  font-family: 'cabinet-grotesk-regular';
  font-size: 16px;
  background: #FFF;
  color: #060D1C;
}

button {
  cursor: pointer;
  font-family: 'cabinet-grotesk-regular';
  outline: none;
  border: none;
}
a {
  text-decoration: none;
}