.transaction-balance {
  display: flex;
  flex-direction: column;
  padding: 0 5%;
  margin-top: 180px;
}
.transaction-balance header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.transaction-balance header h3 {
  font-family: "cabinet-grotesk-medium";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  color: #060D1C;
}
.transaction-balance header .transaction-cta {
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.transaction-balance header .transaction-cta button {
  width: 100%;
  min-width: 170px;
  height: 50px;
  border-radius: 50px;
  transition: all 0.4s ease-in-out;
}
.transaction-balance header .transaction-cta .widthrawal-btn {
  background: none;
  border: 0.638138px solid #8490FF;
  font-family: "cabinet-grotesk-regular";
  font-size: 16px;
  color: #060D1C;
}
.transaction-balance header .transaction-cta .widthrawal-btn:hover {
  background: #060D1C;
  color: #FFF;
}
.transaction-balance header .transaction-cta .fund-btn {
  background: #8490FF;
  border: 0.638138px solid #8490FF;
  color: #FFF;
  font-size: 16px;
}
.transaction-balance header .transaction-cta .fund-btn:hover {
  background: #4CD3E3;
}
.transaction-balance main {
  margin-top: 49px;
  display: flex;
  -moz-column-gap: 49px;
       column-gap: 49px;
}
.transaction-balance main div {
  height: 180px;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid rgba(101, 100, 104, 0.19);
  border-radius: 10px;
  padding: 32px;
}
.transaction-balance main .total-available-balance {
  flex-basis: 40%;
  justify-content: space-between;
}
.transaction-balance main .total-available-balance header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.transaction-balance main .total-available-balance header span {
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-column-gap: 0.3rem;
       column-gap: 0.3rem;
  width: 100px;
  height: 36px;
  border: 1px solid rgba(101, 100, 104, 0.19);
  border-radius: 42px;
}
.transaction-balance main .total-available-balance header span .trend-up-icon {
  color: #04A068;
}
.transaction-balance main .total-available-balance header span p {
  color: #060D1C;
}
.transaction-balance main .total-available-balance h3 {
  font-family: "cabinet-grotesk-bold";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  color: #060D1C;
}
.transaction-balance main .create-new-link-div {
  display: flex;
  justify-content: space-between;
  flex-basis: 20%;
  background: #045059;
}
.transaction-balance main .create-new-link-div h4 {
  font-family: "cabinet-grotesk-bold";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #4CD3E3;
}
.transaction-balance main .create-new-link-div a span {
  display: flex;
  -moz-column-gap: 4px;
       column-gap: 4px;
  align-items: center;
  color: #FFF;
}
.transaction-balance main .create-new-link-div a span:hover {
  color: #8490FF;
}

.recent-transaction-table.transaction-list-container header h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #060D1C;
}
.recent-transaction-table.transaction-list-container header span {
  display: flex;
  align-self: center;
  -moz-column-gap: 8px;
       column-gap: 8px;
  background: none;
  width: 120px;
  height: 50px;
  border: 1px solid rgba(101, 100, 104, 0.19);
  border-radius: 50px;
  color: #060D1C;
  cursor: pointer;
}
.recent-transaction-table.transaction-list-container header span .filter-icon {
  font-size: 22px;
  color: #8490FF;
}
.specials {
  display: flex;
  justify-content: space-evenly;
  width: auto;
}
.csv {
  display: flex;
  align-self: center;
  -moz-column-gap: 8px;
       column-gap: 8px;
  background: none;
  width: 120px;
  height: 50px;
  border: 1px solid rgba(101, 100, 104, 0.19);
  border-radius: 50px;
  color: #060D1C;
  cursor: pointer;
}
.csv:hover {
  border-color: #8490FF;
}

.properties-page-filter-options {
  display: flex;
  justify-content: space-between;
  height: 70px;
  background: #FFF;
  column-gap: 4rem;
  margin: 0 3rem;
  margin-top: 1.5rem;
  padding: 0 2rem;
  border-radius: 5px;
}

.filter-option {
  display: flex;
  align-items: center;
  column-gap: .5rem;
  cursor: pointer;
}

.filter-option span {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(26, 26, 26, 0.102);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-family: 'gilroy-medium';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #757575;
}

.filter-option.active-option p,
.filter-option:hover p {
  font-weight: 600;
}

.filter-option.active-option span,
.filter-option span:hover {
  background: #4733AC;
  color: #FFF;
}

.properties-page-filter-options button {
  display: none;
}

.recent-transaction-table.transaction-list-container header span:hover {
  border-color: #8490FF;
}
.recent-transaction-table.transaction-list-container table {
  border-radius: 12px;
}/*# sourceMappingURL=dashboard-transaction.css.map */