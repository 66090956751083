.dashboard-overview-container {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100vh;
    width: 100%;
}

.dashboard-overview-container {
    /* height: 50vh; */
    position: relative !important;
}
.overview-details-container main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 58vh;
    width: 100%;
    background-color:#060D1C;
    background-image: url(../../../Images/bg-noise.svg);
    background-blend-mode: multiply;
    background-position: center;
    /* background-repeat:; */
    padding: 0 5%;
    padding-top: 5%;
}

.user-overview-details {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    column-gap: 2rem;
    /* margin-top: 4rem; */
    /* align-items: center; */
}
.user-avatar-img {
    width: 130px;
}
.user-overview-details h3 {
    font-family: 'cabinet-grotesk-bold';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #FFFFFF;
    margin-bottom: 8px;
}

.user-overview-details p {
    font-family: 'cabinet-grotesk-regular';
    font-weight: 400;
    font-size: 20px;
    color: #f0f0f0df;
}

.balance-cards {
    display: flex;
    column-gap: 3rem;
}
.balance-cards .balance-card:nth-child(2) {
    background-color: #4CD3E3;
}
.balance-card {
    width: 100%;
    width: 440px;
    height: 320px;
    background-color: #8490FF;
    background-image: url(../../../Images/balance-card-bg.svg);
    background-position: center;
    border-radius: 12px;
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
}

.card-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.card-title p {
    font-family: 'cabinet-grotesk-bold';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #F0F0F0;
}
.card-amount h3 {
    font-family: 'cabinet-grotesk-bold';
    font-style: normal;
    font-weight: 800;
    font-size: 42px;
    color: #FFFFFF;
    /* margin-top: 2.5rem; */
}


.balance-card-cta {
    display: flex;
    column-gap: 1rem;
    width: 100%;
}
.balance-card-cta button {
    width: 100%;
    flex-basis: 50%;
    height: 50px;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid #FFFFFF;
    border-radius: 50px;
    font-family: 'cabinet-grotesk-regular';
    font-size: 18px;
    color: #FFF;
}
.create-payment-link-btn button{
    flex-basis: 100% !important;
}




.recent-transaction-table {
    margin-top: 4rem;
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    
}
.recent-transaction-table header {
    display: flex;
    justify-content: space-between;
}
.recent-transaction-table header h4 {
    font-family: 'cabinet-grotesk-medium';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
}
.recent-transaction-table header span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 104px;
    height: 32px;
    background: #060D1C;
    border-radius: 3px;
    color: #FFF;
    border-radius: 32px;
}

.recent-transactions {
    border-collapse: collapse;
    text-align: left;
    width: 100%;
    padding: 1rem 2rem;
    margin-top: 3rem;
    border:1px solid rgba(101, 100, 104, 0.19);
    border-radius: 12px !important;
}

.recent-transactions thead tr {
    border-bottom:1px solid rgba(101, 100, 104, 0.19);
}
.recent-transactions thead tr th {
    font-size: 16px !important;
}

.recent-transactions th,
.recent-transactions td {
    padding: 1.5rem 2rem;
}
.recent-transactions tbody{
    padding: 2rem 0;
}
.recent-transactions tbody tr:nth-of-type(even) {
    background: rgba(71, 51, 172, 0.1);
}
.recent-transactions tr p{
    /* font-family: 'gilroy-medium'; */
    font-size: 14px;
}
.apartment-list-container tr td {
    text-align: left;
    align-items: center;
}
.status {
    color: #CC2828;
}

/* .status {
    width: 120px;
}
.status span {
    align-items: center;
    background: rgba(4, 80, 89, 0.18);
    border: 1px solid #045059;
    font-size: 14px;
    text-align: center;
    color: #045059;
    border-radius: 28px;
} */