.sign-up-page {
    display: flex;
    flex-direction: row;
}

.sign-up-side-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-basis: 40%;
    padding: 4rem;
    padding-bottom: 10%;
    background: url(../../Images/sign-up-bg.svg);
    height: 100vh;

}

.sign-up-side-container h2 {
    font-family: 'cabinet-grotesk-extrabold';
    /* font-family: 'cabinet-grotesk-variable'; */
    font-style: normal;
    font-weight: 800 !important;
    font-size: 48px;
    line-height: 74px;
    letter-spacing: 0.02em;
    color: #F0F0F0;
}

.sign-up-side-container p {
    color: #FFF;
    margin-bottom: 1.5rem;
}

.sign-up-form-container {
    flex-basis: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding: 4rem;
}

.sign-up-form-container header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 4rem;
}
.sign-up-form-container header h2 {
    font-family: 'cabinet-grotesk-bold';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 12px;
    color: #060D1C;
}
.sign-up-form-container header p {
    font-size: 20px;
    color: #656468;
}

.sign-up-form-container form {
    display: flex;
    flex-direction: column;
    row-gap: 48px;
}
.sign-up-form-container form span {
    display: flex;
    flex-direction: column;
}
.sign-up-form-container form label {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 1rem;
}
.sign-up-form-container form input {
    padding:  0 1rem;
    height: 64px;
    background: #FFFFFF;
    border: 1px solid #8490FF;
    border-radius: 5px;
    font-family: 'cabinet-grotesk-regular';
    font-size: 18px;
}
.sign-up-form-container form input::placeholder {
    font-family: 'cabinet-grotesk-regular';
    font-size: 18px;
    color: #ccc;
}

.form-full-name {
    display: flex;
    column-gap: 1.5rem;
    width: 100%;
    flex-direction: row;
}
.form-full-name span {
    flex-basis: 50%;
}


.form-cta {
    margin-top: 64px;
    width: 100%;
}
.form-cta button {
    width: 100%;
    height: 72px;
    background: #8490FF;
    border: 1px solid #8490FF;
    border-radius: 5px;
    font-family: 'cabinet-grotesk-medium';
    font-size: 20px;
    color: #FFF;
    cursor: pointer;
}


.form-cta p {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 20px;
    color: #656468;
}

@media screen and (max-width: 768px) {
    .sign-up-page {
        flex-direction: column;
    }

    .sign-up-form-container {
        padding: 1rem 2rem 2rem 2rem;
    }

    .sign-up-form-container header {
        margin: 1rem 0 2rem 0;
    }

    .sign-up-side-container {
        flex-basis: 30%;
        width: 100%;
        padding: 1.3rem;
    }

    .sign-up-side-container h2 {
        font-size: 32px;
        line-height: 30px;
    }

    .sign-up-form-container form {
        row-gap: 12px;
    }

    .sign-up-form-container form label {
        margin: 0 0 .5rem 0;
    }

    .sign-up-form-container form input {
        padding: 0 .5rem;
    }

    .sign-up-form-container .form-full-name {
        flex-direction: column;
    }
}