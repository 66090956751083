
.customers-container {

    justify-content: center;
    /* background-image: url(../../Images/customer-bg-noise.svg); */
    /* background-color:   #A36C52; */
    background-blend-mode: multiply;
    padding: 5rem 80px;
    min-height: 550px;
    position: relative;
    margin-top: 70px;
}
.customers {
    display: flex;
    column-gap: 4rem;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
}
.customers img {
    flex-basis: 50%;
    /* border-radius: 5px; */
}

.customers article {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    /* background-color: #D63434; */
    background-image: url(../../Images/Frame\ 507.svg);
    background-blend-mode: multiply;
    background-position: center center;
    background-repeat: no-repeat;
    /* min-height: 550px; */
    flex-basis: 50%;
    /* margin: auto 0; */
}

.customers article p {
    font-weight: 500;
    font-size: 20px;
    color: #FFF;
}
.customers article h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 58px;
    display: flex;
    align-items: center;
    color: #F0F0F0;
    max-width: 640px;
    margin-top: 1.5rem;
}

.customers-cta {
    display: flex;
    margin-top: 3rem;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    width: 100%;
    max-width: 436px;
}
.customers-cta button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    flex-basis: 50%;
    height: 55px;
    background: #060D1C;
    color: #FFF;
    border-radius: 55px;
    font-weight: 500;
    z-index: 11;
}
.customers-cta button:nth-child(1) {
    background: none;
    border:  1px solid #FFF;
    background-color: #F0F0F0;

    color: #060D1C;
}
.customers-cta button:nth-child(1):hover {
    background-color: #060D1C;
    color: #FFF;
    transition:  0.5s linear;
}
.customers-cta button:hover {
    background-color: #FFFFFF;
    color: #060D1C;
    transition: all 0.5s linear;

}

.aside-img {
    position: absolute;
    right: 80px;
    bottom: 1rem;
    max-width: 120px;
}

@media (max-width: 1086px) {
    .customers-container {
        padding: 4rem 24px;
    }
    .customers-container article h3 {
        font-size: 32px;
        line-height: 50px;    
    }
}
@media (max-width: 786px) {
    .customers-container {
        width: 100%;
        flex-direction: column-reverse;
        padding: 3rem 1rem;
    }
    .customers {
        flex-direction: column;
    }
    .customers img {
        max-height: 400px;
        object-fit: cover;
        object-position: center;
    }
    .customers article {
    text-align: left;
    padding: 4rem 1.5rem;
    margin-top: 4rem;
    }
    .customers article p {
        font-size: 18px;
    }
    .customers article h3{
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 42px;
        margin-top: 1rem;
    }
    .customers-cta {
        margin: 0 auto;
        margin-top: 2rem;
    }
    .customers-cta button {
        height: 50px;
    }
    .aside-img {
        display: none;
    }
}