.apartment-added-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(26, 26, 26, 0.2);
    width: 100%;
    height: 100vh;
}
.apartment-added-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background: #FFF;
    max-width: 500px;
    height: 400px;
    border-radius: 40px;
    padding:  2rem;
}
.apartment-added-modal img {
    margin-bottom: 1.5rem;
    width: 80px;
}
.apartment-added-modal h4 {
    font-size: 24px;
    color: #333333;
    margin-bottom: .5rem;
}
.apartment-added-modal p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #555555;
}
.apartment-added-modal-cta {
    display: flex;
    column-gap: 1rem;
    margin-top: 1.5rem;
}

.apartment-added-modal-cta .modal-cta {
    height: 55px;
    padding: 0 2rem;
    max-width: 350px;
    background: #4733AC;
    border: 1px solid #4733AC;
    border-radius: 5px;
    color:  #FFF;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.apartment-added-modal-cta .modal-cta:nth-child(1) {
    background: none;
    color: #4733AC;
}
.bank-div {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.bank-details{
    display: flex;
    align-items: center;
    /* justify-content: space-around; */
}
.bank-details h6 {
    font-size: large;
    margin-right: 10px;
}
.copied {
    color: red;
    margin-left: 2px;
}

/* .m-btn {

} */