.heroe-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 80px;
}

.heroe-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 180px;

}
.heroe-content h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    color: #131212;
    width: 100%;
    max-width: 900px;
}
.heroe-content h1 span {
    color: #060D1C;
}
.heroe-content .p-quote {
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: rgba(19, 8, 3, 0.8);
    width: 100%;
    max-width: 550px;
    margin-top: .5rem;
    text-align: center;
}
.header-cta {
    display: flex;
    column-gap: 1rem;
    margin-top: 2rem;
    width: 100%;
    max-width: 400px;
}
.header-cta button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    column-gap: .3rem;
    border: none;
    outline: none;
    flex-basis: 50%;
    height: 55px;
    background: #060D1C;
    border-radius: 55px;
    font-weight: 500;
    font-size: 16px !important; 
    text-align: center !important;
    color: #FFFFFF;
}


.header-cta button:nth-child(2) {
    background-color: #060D1C;
}
.header-cta button p{
    margin: 0;
    border-radius: 5px;
    font-weight: 500;
    font-size: 15px;
    color: #FFFFFF;
}
.heroe-container footer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    column-gap: 2rem;
    row-gap: 2rem;
    margin-top: 80px;
    padding: 0px;
    width: 100%;
    max-width: 1400px;
}
.heroe-container footer .feature{
    display: flex;
    flex-direction: column;
    height: 450px;
    border: 1px solid rgba(216, 91, 29, 0.5);
    padding:  1.5rem;
    border-radius: 5px;
}

.feature img {
    border-radius: 5px;
    object-fit: cover;
    object-position: center;
    flex-basis: 70%;
}

.feature span {
    display: flex;
    margin-top: 1.5rem;
    overflow: hidden;
}
.feature article {
    display: flex;
    flex-direction: column;
    row-gap: .6rem;
    overflow: hidden;
}
.feature article h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #131212;
    overflow: hidden;
}
.feature article p {
    color: rgba(19, 8, 3, 0.8);
    line-height: 25px;
    font-size: 15px;
    overflow: hidden;
}

.feature button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 36px;
    height: 36px;
    margin-top: auto;
    background: #060D1C;
    color: #FFF;
    font-size: 20px;
    border-radius: 50%;
    border: none;
    outline: none;
}

@media (max-width: 1086px) {
    .heroe-container {
        padding: 0 24px;
    }

    .heroe-container footer .feature{
        padding: 1rem;
        height: 400px;
    }
}

@media (max-width: 786px) {
    .heroe-container {
        padding: 0 1rem;
    }
    .heroe-content {
        margin-top: 140px;
    }
    .heroe-container footer {
        flex-direction: column;
        padding: 0rem;
    }
    .heroe-content h1 {
        font-weight: 800;
        font-size: 30px;
        line-height: 44px;
    }
    .heroe-content p {
        margin-top: 1rem;
    }
    .header-cta {
        column-gap: .5rem;
    }
    .header-cta button {
        height: 50px;
    }

    .heroe-container footer .feature{
        padding: 1rem;
        height: 350px;
    }
    .feature span {
        margin-top: 1rem;
    }
}