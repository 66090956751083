.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.footer-nav-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    justify-content: center;
    width: 100%;
    max-width: 1400px;
    column-gap: 2rem;
    row-gap: 3rem;
    padding: 0 80px;
    align-items: center;
    margin: 0 auto;
    margin-top: 4rem;
}

.footer-nav {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}
.footer-nav h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    color: #130803;
}
.footer-nav ul {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}
.footer-nav ul a {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #131212;
}
.footer-logo img{
    width: 300px;
}
.footer-logo .contact-sm {
    margin-top:2rem;
    justify-content: center;
    column-gap: 1rem;
}
.footer-logo .contact-sm .iconify {
    color: #107670;
}


.download-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    margin: 3rem auto;
    border-top: 1px solid rgba(102, 102, 102, 0.5);
}
.download-btn {
    display: flex;
    justify-content: center;
    column-gap: 1rem;
    margin-top: 2rem;
}
.download-btn button {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    width: 100%;
    height: 50px;
    background: #000000;
    border-radius: 5px;
    border: none;
    outline: none;
    color: #FFF;
    overflow: hidden;
}
.download-btn .iconify,
.download-btn.web-signin .iconify {
    font-size: 28px !important;
    /* width: 40px; */
    /* flex-basis: 30%; */
}
.download-btn button span {
    display: flex;
    flex-direction: column;
    row-gap: .2rem;
    justify-content: left;
    align-items: flex-start;
}
.download-btn button span p:nth-child(1) {
    font-size: 10px;
}

.download-btn.web-signin {
    margin-left: 1rem;
}
.download-btn.web-signin button{
    background: #D85B1D;
}

@media (min-width: 786px) {
    .download-btn button {
        min-width: 180px;
    }
}
@media (max-width: 1086px) {
    .footer-nav-container {
        padding: 0 24px;
    }
}



@media (max-width: 786px) {

    .footer-nav-container {
        row-gap: 2rem;
        align-items: center;
        text-align: center;
       padding:  0 1rem;
    }
    .download-button-container {
        flex-direction: column;
    }
    .footer-nav h6 {
        font-size: 16px;
    }

    .download-btn {
        padding: 0 1rem;
        column-gap: .3rem;
        width: 100%;
    }
    .download-btn button {
        display: flex;
        height: 55px;
    }
    .download-btn.web-signin button{
        max-width: 180px;
    }
}