.settings-container {
  margin-top: 180px;
  padding: 0 5%;
}

.settings-container header {
  display: flex;
  justify-content: space-between;
}

.settings-container header h3 {
  font-family: "cabinet-grotesk-bold";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #060D1C;
}

.settings-container header span {
  display: flex;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
}

.settings-container header span button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  width: 100%;
  min-width: 200px;
  height: 50px;
  border-radius: 50px;
  color: #FFF;
  font-size: 16px;
}

.settings-container header span .discard-btn {
  border: 0.638138px solid #CC2828;
  color: #060D1C;
}

.settings-container header span .discard-btn:hover {
  transition: all 0.4s linear;
  background-color: #CC2828;
  color: #FFF;
}

.settings-container header span .save-changes-btn {
  background: #8490FF;
}

.settings-container header span .save-changes-btn:hover {
  transition: all 0.4s linear;
  background: #4CD3E3;
}

.settings-container .settings-tabs {
  margin-top: 52px;
  display: flex;
  justify-content: space-between;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
  padding: 0.5rem;
  height: 80px;
  background: rgba(132, 144, 255, 0.1);
  border-radius: 12px;
}

.settings-container .settings-tabs span {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.settings-container .settings-tabs span p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #656468;
}

.settings-container .settings-tabs .active-tab,
.settings-container .settings-tabs span:hover {
  transition: all 0.4s linear;
  background: #FFF;
  border-radius: 8px;
  cursor: pointer;
}

.settings-container .settings-tabs .active-tab p,
.settings-container .settings-tabs span:hover p {
  color: #060D1C;
}

.settings-container .settings-content {
  display: none !important;
}

.settings-container .active-settings-content {
  display: block;
}

.settings-container .settings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 873px;
  margin: 0 auto;
  margin-top: 56px;
}

.settings-container .settings header {
  display: flex;
  flex-direction: column;
}

.settings-container .settings header h4 {
  font-family: "cabinet-grotesk-bold";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #060D1C;
}

.settings-container .settings header p {
  font-family: "Inter", sans-serif;
  margin-top: 0.5rem;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #656468;
}

.settings-container .settings form {
  display: flex;
  flex-direction: column;
  margin-top: 49px;
  margin-bottom: 2rem;
  background: rgba(132, 144, 255, 0.1);
  border-radius: 12px;
  padding: 3rem 2rem;
}

.settings-container .settings form div {
  display: flex;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
}

.settings-container .settings form div span {
  flex-basis: 50%;
}

.settings-container .settings form span {
  display: flex;
  flex-direction: column;
}

.settings-container .settings form span label {
  font-family: "cabinet-grotesk-medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #060D1C;
  margin-bottom: 1.5rem;
}

.settings-container .settings form span input,
.settings-container .settings form span select {
  border: 1px solid rgba(132, 144, 255, 0.5);
  border-radius: 5px;
  height: 54px;
  background: none;
  padding: 0 1.5rem;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  margin-bottom: 42px;
  color: #060D1C;
}

/*# sourceMappingURL=dashboard-settings.css.map */

.save-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  width: 100%;
  min-width: 200px;
  height: 50px;
  border-radius: 50px;
  color: #FFF;
  font-size: 16px;
  background: #8490FF;
}

.options {
  display: flex;
  flex-direction: column;
}

.options span {
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  /* align-items: center; */
  -moz-column-gap: 1.5rem;
        column-gap: 1.5rem;
}

.options span input {
  margin-bottom: 0;
  width: 24px;
}

.options span p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #060D1C;
}

.formy input {
  border: 1px solid rgba(132, 144, 255, 0.5);
  border-radius: 5px;
  height: 54px;
  background: none;
  padding: 0 1.5rem;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  margin-bottom: 42px;
}
.formy {
  display: flex;
  flex-direction: column;
  margin-top: 49px;
  margin-bottom: 2rem;
  background: rgba(132, 144, 255, 0.1);
  border-radius: 12px;
  padding: 3rem 2rem;
}