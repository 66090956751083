.list-container {
  margin-top: 180px;
  padding: 0 5%;
}
.list-container header {
  display: flex;
  justify-content: space-between;
}
.list-container header h3 {
  font-family: "cabinet-grotesk-bold";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #060D1C;
}
.list-container header button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  width: 100%;
  min-width: 200px;
  height: 50px;
  border-radius: 50px;
  background: #8490FF;
  color: #FFF;
  font-size: 16px;
}
.list-container header button:hover {
  background: #4CD3E3;
  transition: all 0.4s ease;
}/*# sourceMappingURL=list.css.map */

.disable {
  color: rgb(252, 73, 73);
  cursor: pointer;
}