.create-transaction-container {


    header {
        display: flex;
        justify-content: space-between;
        margin-top: 180px;
        padding: 0 5%;


        div {
            display: flex;
            align-items: center;
            column-gap: 1rem;
        }
        span {
            display: flex;
            column-gap: 1rem;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                background: none;
                width: 100%;
                min-width: 200px;
                height: 50px;
                border-radius: 50px;
                font-size: 16px;
            }

            .save-draft-btn {
                border: 0.638138px solid #CC2828;
            }
            .create-link-btn {
                background: #8490FF;
                color: #FFF;
            }
        }
    }


    main {
        padding: 0 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 873px;
        margin: 0 auto;
        margin-top: 56px;

        h4 {
            font-family: 'cabinet-grotesk-bold';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            color: #060D1C;
        }


        form {
            display: flex;
            flex-direction: column;
            
            margin-top: 49px;
            margin-bottom: 2rem;
            background: rgba(132, 144, 255, 0.1);
            border-radius: 12px;
            // min-height: 600px;
            padding: 3rem 2rem;


            label {
                font-family: 'cabinet-grotesk-medium';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                color: #060D1C;
                margin-bottom: 1.5rem;
            }
            input {
                border: 1px solid rgba(132, 144, 255, 0.5);
                border-radius: 5px;
                height: 54px;
                background: none;
                padding: 0 1.5rem;
                font-size: 16px;
                font-family: 'Inter', sans-serif;
                font-weight: 400;
                margin-bottom: 42px;
            }

            input::placeholder {
                font-size: 16px;
            }

            .payment-options {
                display: flex;
                flex-direction: column;

                span {
                    display: flex;
                    margin-bottom: 0;
                    align-items: center;
                    column-gap: 1.5rem;

                    input {
                        margin-bottom: 0;
                        width: 24px;
                    }
                    p {
                        font-family: 'Inter', sans-serif;
                        font-weight: 400;
                        font-size: 18px;
                        color: #060D1C;
                    }
                }
            }
        }
    }
}