.settings-container {
    margin-top: 180px;
    padding: 0 5%;


    header {
        display: flex;
        justify-content: space-between;


        h3 {
            font-family: 'cabinet-grotesk-bold';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            color: #060D1C;

        }

        span {
            display: flex;
            column-gap: 1rem;


            button {
                display: flex;
                justify-content: center;
                align-items: center;
                background: none;
                width: 100%;
                min-width: 200px;
                height: 50px;
                border-radius: 50px;
                color: #FFF;
                font-size: 16px;

            }

            .discard-btn {
                border: 0.638138px solid #CC2828;
                color: #060D1C;
            }
            .discard-btn:hover {
                transition: all 0.4s linear;
                background-color: #CC2828;
                color: #FFF;
            }
            .save-changes-btn {
                background: #8490FF;
            }
            .save-changes-btn:hover {
                transition: all 0.4s linear;
                background:  #4CD3E3;
            }
        }

    }





    .settings-tabs {
        margin-top: 52px;
        display: flex;
        justify-content: space-between;
        column-gap: 1rem;
        padding:.5rem;
        height: 80px;
        background: rgba(132, 144, 255, 0.1);
        border-radius: 12px;

        span {
            // background: #FFF;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;


            p {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                color: #656468;

            }
        }
        .active-tab,
        span:hover {
            transition: all 0.4s linear;
            background: #FFF;
            border-radius: 8px;
            cursor: pointer;

            p {
                color: #060D1C;
            }
        }
    }

    .settings-content {
        display: none !important;
      }
      .active-settings-content {
        display: block;
      }

    .settings {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 873px;
        margin: 0 auto;
        margin-top: 56px;

        header {
            display: flex;
            flex-direction: column;


            h4 {
                font-family: 'cabinet-grotesk-bold';
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                color: #060D1C;
            }
            p {
                font-family: 'Inter', sans-serif;
                margin-top: .5rem;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #656468;
            }
        }

        form {
            display: flex;
            flex-direction: column;
            
            margin-top: 49px;
            margin-bottom: 2rem;
            background: rgba(132, 144, 255, 0.1);
            border-radius: 12px;
            // min-height: 600px;
            padding: 3rem 2rem;

            div {
                display: flex;
                column-gap: 1rem;

                span {
                    flex-basis: 50%;
                }
            }

            span {
                display: flex;
                flex-direction: column;
                

                label {
                    font-family: 'cabinet-grotesk-medium';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    color: #060D1C;
                    margin-bottom: 1.5rem;
                }
                input,
                select {
                    border: 1px solid rgba(132, 144, 255, 0.5);
                    border-radius: 5px;
                    height: 54px;
                    background: none;
                    padding: 0 1.5rem;
                    font-size: 16px;
                    font-family: 'Inter', sans-serif;
                    font-weight: 400;
                    margin-bottom: 42px;
                    color: #060D1C;
                }
    
            }

        }
    }



}