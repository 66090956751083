.sign-in-container {
    background: url(../../Images/sign-in-bg.svg) !important;
}
.sign-in-form-cta button {
    background: #4CD3E3;
    border-color: #4CD3E3;
}
.sign-in-form-cta button:hover {
    background: #8490FF;
    border-color: #8490FF;
    transition: 0.5s ease-in-out;
}

.forgot-password {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 20px;
    color: #CC2828;
}